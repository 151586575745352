import React from "react"
import { FlexboxGrid, Col, Container, Header, Content } from "rsuite"
import Helmet from "react-helmet"

import SEO from "../components/seo"
import TopHeader from "../components/topHeader"
import CustomMenu from "../components/custommenu"
import FooterSection from "../components/footer"

const Success = () => {
  setTimeout(() => {
    window.location.replace("/")
  }, 5000)
  return (
    <>
      <SEO title="Succès" />
      <Helmet>
        <title>Succès</title>
        <meta name="description" content="Merci!" />
      </Helmet>
      <Container>
        <Header>
          <div className="block-header">
            <CustomMenu />
            <TopHeader />
            <FlexboxGrid justify="center">
              <FlexboxGrid.Item
                componentClass={Col}
                colspan={24}
                md={6}
              ></FlexboxGrid.Item>
              <FlexboxGrid.Item
                componentClass={Col}
                colspan={24}
                md={6}
              ></FlexboxGrid.Item>
            </FlexboxGrid>
          </div>
        </Header>
        <Content id="page-wrap">
          <div className="bg-contact2">
            <div class="container-contact2">
              <div class="wrap-contact2">
                <div id="main" className="alt">
                  <section id="one">
                    <div className="inner">
                      <header className="major">
                        <h1 className="success-merci">
                          Merci d'avoir contacté les bonnes nouvelles!
                        </h1>
                      </header>
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </div>
        </Content>
        <FooterSection />
      </Container>
    </>
  )
}

export default Success
